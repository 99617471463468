import React, { useState } from "react";
// import useContent from "../custom-hooks/useContent";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import FeatureWrapper from "../components/Header/FeatureWrapper";
import FeatureTitle from "../components/Header/FeatureTitle";
import FeatureSubTitle from "../components/Header/FeatureSubTitle";
import HeaderLink from "../components/Header/HeaderLink";
import FooterCompound from "../compounds/FooterCompound";
import LocalizedStrings from 'react-localization';



const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyCmygbZmRVuE_MeGZ5kwNXRNN3RdGIEXXc&playlistId=';
export async function getServerSideProps(id) {
  const res = await fetch(YOUTUBE_PLAYLIST_ITEMS_API + id + '&part=snippet,contentDetails');
  const data = await res.json();
  return {
    props: {
      data
    }
  };
}

function CreditsPage() {
  let strings = new LocalizedStrings({
    en:{
      menu_a: "Home",
      menu_b: "Credits",
      how: "How works the experience?"
    },
    it: {
      menu_a: "Home",
      menu_b: "Credits",
      how: "Come posso visitare la mostra?"
  
    }
   });
  strings.setLanguage('it');
  
  const [category] = useState("content");

  return (
    <>
      <HeaderWrapper className="header-wrapper-browse">
        <NavBar className="navbar-browse">
          <Logo />
          <HeaderLink
            className={
              category === "content" ? "header-link-bold" : "header-link"
            }
            href="/"
          >
            { strings.menu_a }
          </HeaderLink>
          <HeaderLink
            className={
              category === "credits" ? "header-link-bold" : "header-link"
            }
            href="/#credits"
          >
            { strings.menu_b }
          </HeaderLink>
          <HeaderLink
            className={
              category === "credits" ? "header-link-bold " : "header-link "
            }
            href="/en"
          >
            EN
          </HeaderLink>
        </NavBar>
        <FeatureWrapper>
          <FeatureTitle className="feature-title-browse">
            The Tree Of Tales
          </FeatureTitle>
          <FeatureSubTitle className="feature-subtitle-browse">
            Tolkien Exhibition
          </FeatureSubTitle>
          <div >
            <a className="intro-button" href="/intro" >
              Mostra Virtuale
            </a>
            <a className="intro-button" href="/#PLBQKCyMz1HZkN12jAUefazaOubdlAKpYZ">
              Video
            </a>
          </div>
        </FeatureWrapper>
      </HeaderWrapper>
      <div className="credits">
        <h3>CREDITS</h3>
        <p>
            CURATORI Giuseppe Pezzini, Emmanuele Riu<br />
            REDAZIONE Roger Sylvester, Michela Young, Oronzo Cilli<br />
            ART DIRECTOR Paolo Morandi<br />
            COORDINAMENTO PRODUZIONE Collarino & Morando Communicazione<br />
            PROJECT MANAGER Matteo Bruschi<br />
        </p>
        <p>
            Per l’installazione 3D (prodotta da API S.r.l)<br />
            PROJECT MANAGER E LEVEL DESIGNER: Biancamaria Mori <br />
            PROGRAMMERS Carlo Gioventù, Erik Castiglione<br />
            3D GENERALIST Andrea Guerra<br />
            LEVEL DESIGNER Sara Sorano<br />
        </p>
        <p>
            INTEGRAZIONE WEB Lorenzo Speranza<br />
            STREAMING Pureweb<br />
        </p>
        <p>
            Produzione hotspots (video, audio, e pannelli):<br />
            POSTPRODUZIONE Simone Cerrato, Marta Riu, Francesco Presutti, Roberta Primo<br />
            ANIMAZIONI GRAFICHE  2D Fausto Collarino<br />
            COLONNE SONORE Martino Vacca e Alessandro Artico<br />
            TECNICO AFTER EFFECTS Lukas Danihel<br />
        </p>
        <p>
            VOCI ITALIANE<br />Fabio Ardu, Giorgio Bonino, Massimo Corvo, Elisa Gandolfi, Samia Kassir, Paolo Malgioglio, Davide Perino, Giorgio Sanna, Mario Zucca
        </p>
        <p>
            VOCI INGLESE<br />
            John Burke. Simon D’Aquino, Anthony Errington, Washim Gafoor, David Nagel, Siobhan O’Shea, Cormac Rowe, Richard Solomon, Daniel Sylvester, Roger Sylvester, Marco Young, Michela Young
        </p> 
        <p>
            TESTI E RICERCA<br />
            Giuseppe Pezzini, Emmanuele Riu, Michela Young, Roger Sylvester, Matteo Bruschi, Oronzo Cilli, Paolo Morandi, Guglielmo Spirito, Hamish Williams.
            <br />Prima parte (La vita): Anna Arigossi, Davide Corti, Chiara Di Santis, Anthony Errington, Enrico Manzon, Chiara Ferrario, Trevor Ferros, Abigail Parisaca Vargas, Laura Staccoli, Paolo Tripoli, Lucia Vannini, Alessandra Vittiglio, Benedetta Zeni.
            <br />Seconda parte (La creatività): Anine Englund, Clinton Collister, Trevor Hart, Rebekah Lamb, Siobhan Maloney, Sarah Moffit, Austin Moyers, James Smoker, Michael Thames, Paul Treschow, Brendan Wolfe, Judith Wolfe.
            <br />Terza parte (Il Signore degli Anelli): Luca Arrighini, Giacomo Astrua, Anna Bardazzi, Andrea Budelli, Edoardo Bernardi, Stefano Campisi, Federico Coscia, Marta Folcarelli, Davide Michelis, Simone Michelis, Giovanni Minghetti, Chiara Nejrotti, Pier Luigi Pezzini, Stefano Pistillo, Margherita Scalon, Ivano Sassanelli.
        </p>
        <p>
            Video Ainulindalë<br />
            VIDEOMAKER Mario Pietro Brioschi<br />
            ARTWORK Giovanni Bonapace, Fausto Collarino<br />
            MUSICA Nicolò Facciotto, Federico Mecozzi, Ivan Tiraferri<br />
            VIOLONCELLO E CONTRABBASSO Anselmo Pelliccioni<br />
            TROMBE Gioele Umberti Foppa<br />
            OBOE Ilaria De Maximy<br />
            CORNO Massimo Mondaini<br />
            FLAUTO Ilaria Moriconi<br />
            VOCE: Giorgio Sanna (Italiano), Anthony Errington (Inglese)<br />
        </p>
      </div>
        
      <FooterCompound />
    </>
  );
}

export default CreditsPage;
