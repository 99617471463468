import React, { useState } from "react";
// import useContent from "../custom-hooks/useContent";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import FeatureWrapper from "../components/Header/FeatureWrapper";
import FeatureTitle from "../components/Header/FeatureTitle";
import FeatureSubTitle from "../components/Header/FeatureSubTitle";
import PlayButton from "../components/Header/PlayButton";
import HeaderLink from "../components/Header/HeaderLink";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import SlideWrapper from "../components/Movies/SlideWrapper";
import SlideTitle from "../components/Movies/SlideTitle";
import AllCardsWrapper from "../components/Movies/AllCardsWrapper";
import CardWrapper from "../components/Movies/CardWrapper";
import CardImage from "../components/Movies/CardImage";
import CardFooter from "../components/Movies/CardFooter";
import CardTitle from "../components/Movies/CardTitle";
import CardDescription from "../components/Movies/CardDescription";
import CardFeatureWrapper from "../components/Movies/CardFeatureWrapper";
import CardFeatureClose from "../components/Movies/CardFeatureClose";
import PlayerVideo from "../components/Movies/PlayerVideo";
import PlayerOverlay from "../components/Movies/PlayerOverlay";
import FooterCompound from "../compounds/FooterCompound";
// import VIDEOdata from "../data/video.json";
// import videoUrl from '../videos/video.mp4';
// import playlist from '../data/playlist.json';
import LocalizedStrings from 'react-localization';

const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyCmygbZmRVuE_MeGZ5kwNXRNN3RdGIEXXc&maxResults=10&playlistId=';
export async function getServerSideProps(id) {
  const res = await fetch(YOUTUBE_PLAYLIST_ITEMS_API + id + '&part=snippet,contentDetails');
  const data = await res.json();
  return {
    props: {
      data
    }
  };
}



function IntroPage({dataParentToChild}) {
  let strings = new LocalizedStrings({
    en:{
      menu_a: "Home",
      menu_b: "Credits",
      how: "How works the experience?"
    },
    it: {
      menu_a: "Home",
      menu_b: "Credits",
      how: "Come posso visitare la mostra?"
  
    }
   });
  strings.setLanguage('it');
  
  // const pl = playlist;
  // pl.forEach(p => {
  //   getServerSideProps(p.id).then(data => {
  //     if (data && data.props && data.props.data && data.props.data.items && data.props.data.items.length > 0) {
  //       p.items = data.props.data.items;
  //     }
  //     p.items.forEach(item => {
  //       item.category = p.title;
  //     })
  //   })
  // })
  
  console.log('data', dataParentToChild);
  
  

  const [category] = useState("content");
  // const currentCategory = category === "films" ? films : series;
  const [showCardFeature, setShowCardFeature] = useState(false);
  const [activeItem, setActiveItem] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  // const [language, setLanguage] = useState(strings);
  const list = dataParentToChild;

  return (
    <>
      <HeaderWrapper className="header-wrapper-intro">
        <NavBar className="navbar-browse">
          <Logo />
          <HeaderLink
            className={
              category === "content" ? "header-link-bold black" : "header-link black"
            }
            href="/"
          >
            { strings.menu_a }
          </HeaderLink>
          <HeaderLink
            className={
              category === "credits" ? "header-link-bold black" : "header-link black"
            }
            href="/crediti"
          >
            { strings.menu_b }
          </HeaderLink>
          <HeaderLink
            className={
              category === "credits" ? "header-link-bold black" : "header-link black"
            }
            href="/en/intro"
          >
            EN
          </HeaderLink>
        </NavBar>
        <FeatureWrapper>
          <FeatureTitle className="feature-title-browse black">
          { strings.how }
          </FeatureTitle>
          <FeatureSubTitle className="feature-subtitle-browse black">
            Tolkien Exhibition
          </FeatureSubTitle>
          <div className="d-flex">
            <a className="intro-button" href="/watch">
              Mostra Virtuale
            </a>
            </div>
            <div>
            <button className="intro-button" onClick={() => {
                      setShowCardFeature(true);
                      setActiveItem(list[0].items[0]);
                      setShowPlayer(true);
                    }}>
              Visita Guidata (30min)
            </button>
            <button className="intro-button" onClick={() => {
                      setShowCardFeature(true);
                      setActiveItem(list[0].items[1]);
                      setShowPlayer(true);
                    }}>
              Visita Guidata (75min)
            </button>
            <button className="intro-button" onClick={() => {
                      setShowCardFeature(true);
                      setActiveItem(list[1].items[1]);
                      setShowPlayer(true);
                    }}>
              Webinar / Live Session
            </button>
            </div>
        </FeatureWrapper>
      </HeaderWrapper>
      <AllSlidesWrapper>
        {list.map((slideItem) => (
          <SlideWrapper key={`${slideItem.id}`}>
            <SlideTitle>{slideItem.title}</SlideTitle>
            <AllCardsWrapper>
              {(slideItem.items) ? slideItem.items.map((cardItem) => (
                <CardWrapper key={cardItem.id}>
                  <CardImage
                    onClick={() => {
                      setShowCardFeature(true);
                      setActiveItem(cardItem);
                      setShowPlayer(true);
                    }}
                    src={`https://img.youtube.com/vi/${cardItem.contentDetails.videoId}/0.jpg`}
                  />
                  <CardFooter>{cardItem.snippet.title}</CardFooter>
                </CardWrapper>
              )): ''}
            </AllCardsWrapper>
            {showCardFeature &&
            slideItem.title ===  activeItem.category ? (
              <CardFeatureWrapper
                style={{
                  backgroundImage: `url(../images/${category}/${activeItem.genre}/${activeItem.slug}/large.jpg)`,
                }}
              >
                <CardTitle>{activeItem.title}</CardTitle>
                <CardDescription>{activeItem.description}</CardDescription>
                <CardFeatureClose onClick={() => setShowCardFeature(false)} />
                <PlayButton onClick={() => setShowPlayer(true)}>
                  Play
                </PlayButton>
                {showPlayer ? (
                  <PlayerOverlay onClick={() =>{ 
                    setShowPlayer(false);
                    setShowCardFeature(false);
                    }}>
                    <PlayerVideo src={activeItem.contentDetails.videoId} type="video/mp4" youtube="true"/>
                  </PlayerOverlay>
                ) : null}
              </CardFeatureWrapper>
            ) : null}
          </SlideWrapper>
        ))}
      </AllSlidesWrapper>
      <FooterCompound />
    </>
  );
}

export default IntroPage;
