/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./FooterStyles.css";

function FooterLink({ children, ...restProps }) {
  return (
    <a href={restProps.href ? restProps.href : "https://thetreeoftales.it"}  className="footer-link" {...restProps}>
      {children}
    </a>
  );
}

export default FooterLink;
