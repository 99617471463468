/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
// import useContent from "../custom-hooks/useContent";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import FeatureWrapper from "../components/Header/FeatureWrapper";
import FeatureTitle from "../components/Header/FeatureTitle";
import FeatureSubTitle from "../components/Header/FeatureSubTitle";
import PlayButton from "../components/Header/PlayButton";
import HeaderLink from "../components/Header/HeaderLink";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import SlideWrapper from "../components/Movies/SlideWrapper";
import SlideTitle from "../components/Movies/SlideTitle";
import AllCardsWrapper from "../components/Movies/AllCardsWrapper";
import CardWrapper from "../components/Movies/CardWrapper";
import CardImage from "../components/Movies/CardImage";
import CardFooter from "../components/Movies/CardFooter";
import CardTitle from "../components/Movies/CardTitle";
import CardDescription from "../components/Movies/CardDescription";
import CardFeatureWrapper from "../components/Movies/CardFeatureWrapper";
import CardFeatureClose from "../components/Movies/CardFeatureClose";
import PlayerVideo from "../components/Movies/PlayerVideo";
import PlayerOverlay from "../components/Movies/PlayerOverlay";
import FooterCompound from "../compounds/FooterCompound";
// import VIDEOdata from "../data/video.json";
// import videoUrl from '../videos/video.mp4';
// import playlist from '../data/playlist.json';
import LocalizedStrings from 'react-localization';

import rimini from "../images/MEETING-new-logo-IST-bianco.png";
import oxford from "../images/nse-6228475226791087856-a3872f64-ae75-4f07-a3d6-6faffc412cdc.jpg";
import standrews from "../images/02-standard-white.png";
import apisrl from "../images/logo_2020_w.png";



// const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyCmygbZmRVuE_MeGZ5kwNXRNN3RdGIEXXc&maxResults=10&playlistId=';
// export async function getServerSideProps(id) {
//   const res = await fetch(YOUTUBE_PLAYLIST_ITEMS_API + id + '&part=snippet,contentDetails');
//   const data = await res.json();
//   return {
//     props: {
//       data
//     }
//   };
// }

function BrowsePage({dataParentToChild}) {
  let strings = new LocalizedStrings({
    en:{
      menu_a: "Home",
      menu_b: "Credits",
      how: "How works the experience?"
    },
    it: {
      menu_a: "Home",
      menu_b: "Credits",
      how: "Come posso visitare la mostra?"
  
    }
   });
  strings.setLanguage('it');
  // const pl = playlist;
  // pl.forEach(p => {
  //   getServerSideProps(p.id).then(data => {
  //     p.items = data.props.data.items;
  //     p.items.forEach(item => {
  //       item.category = p.title;
  //     })
  //   })
  // })
  
  console.log('data', dataParentToChild);


  const [category] = useState("content");
  // const currentCategory = category === "films" ? films : series;
  const [showCardFeature, setShowCardFeature] = useState(false);
  const [activeItem, setActiveItem] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  // const [listed, setList] = useState(true);
  const list = dataParentToChild;
  // setList(list);
  // console.log(listed);

  return (
    <>
      <HeaderWrapper className="header-wrapper-browse">
        <NavBar className="navbar-browse">
          <Logo />
          <HeaderLink
            className={
              category === "content" ? "header-link-bold" : "header-link"
            }
            href="/"
          >
            { strings.menu_a }
          </HeaderLink>
          <HeaderLink
            className={
              category === "credits" ? "header-link-bold" : "header-link"
            }
            href="/crediti"
          >
            { strings.menu_b }
          </HeaderLink>
          <HeaderLink
            className={
              category === "credits" ? "header-link-bold " : "header-link "
            }
            href="/en"
          >
            EN
          </HeaderLink>
        </NavBar>
        <FeatureWrapper>
          <FeatureTitle className="feature-title-browse">
            The Tree Of Tales
          </FeatureTitle>
          <FeatureSubTitle className="feature-subtitle-browse">
            Tolkien Exhibition
            <div>
              <img src={oxford} className="img-responsive" alt="Oxford, University of"/>
              <img src={standrews} className="img-responsive" alt="St Andrews"/>
              <img src={apisrl} className="img-responsive" alt="API SRL"/>
              <img src={rimini} className="img-responsive" alt="Rimini Meeting"/>
            </div>
          </FeatureSubTitle>
          <div >
            <a className="intro-button" href="/intro" >
              Mostra Virtuale
            </a>
            <a className="intro-button" href="/#PLBQKCyMz1HZkN12jAUefazaOubdlAKpYZ">
              Video
            </a>
            <a className="intro-button" href="https://www.itacaedizioni.it/catalogo/the-tree-of-tales-tolkien/" target="_blank">
              Catalogo
            </a>
          </div>
          
        </FeatureWrapper>
      </HeaderWrapper>

      <AllSlidesWrapper>
        {list.map((slideItem) => (
          <SlideWrapper key={`${slideItem.id}`}>
            <SlideTitle><a id={slideItem.id} href={`/#${slideItem.id}`}>{slideItem.title}</a></SlideTitle>
            <AllCardsWrapper>
              {(slideItem.items) ? slideItem.items.map((cardItem) => (
                <CardWrapper key={cardItem.id}>
                  <CardImage
                    onClick={() => {
                      setShowCardFeature(true);
                      setActiveItem(cardItem);
                      setShowPlayer(true);
                    }}
                    src={`https://img.youtube.com/vi/${cardItem.contentDetails.videoId}/0.jpg`}
                  />
                  <CardFooter>{cardItem.snippet.title}</CardFooter>
                </CardWrapper>
              )): ''}
            </AllCardsWrapper>
            {showCardFeature &&
            slideItem.title ===  activeItem.category ? (
              <CardFeatureWrapper
                style={{
                  backgroundImage: `url(../images/${category}/${activeItem.genre}/${activeItem.slug}/large.jpg)`,
                }}
              >
                <CardTitle>{activeItem.title}</CardTitle>
                <CardDescription>{activeItem.description}</CardDescription>
                <CardFeatureClose onClick={() => setShowCardFeature(false)} />
                <PlayButton onClick={() => setShowPlayer(true)}>
                  Play
                </PlayButton>
                {showPlayer ? (
                  <PlayerOverlay onClick={() =>{ 
                    setShowPlayer(false);
                    setShowCardFeature(false);
                    }}>
                    <PlayerVideo src={activeItem.contentDetails.videoId} type="video/mp4" youtube="true"/>
                  </PlayerOverlay>
                ) : null}
              </CardFeatureWrapper>
            ) : null}
          </SlideWrapper>
        ))}
      </AllSlidesWrapper>
      <FooterCompound />
    </>
  );
}

export default BrowsePage;
