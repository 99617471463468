import React from "react";
import "./MoviesStyles.css";
import ReactPlayer from 'react-player';
// const controls = true;
// const pip = true;

function PlayerVideo({ children, ...restProps }) {
  if (restProps.youtube) {
    return (
    <div className='player-wrapper'>
    <ReactPlayer
      className='react-player'
      url={`https://www.youtube.com/watch?v=${restProps.src}`}
      width='80%'
      height='80%'
      config={{
        youtube: {
          playerVars: {
            showinfo: 0,
            controls: false,
            pip: true,
            modestbranding: 1
          }
        }
      }}
    />
  </div>)
  } else {
    return (
      <video className="player-video" controls {...restProps}>
        <source {...restProps} />
        <track kind="captions" srcLang="en" label="english_captions" />
      </video>
    );
  }
  
}

export default PlayerVideo;
