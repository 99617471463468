import React from "react";
import "./HeaderStyles.css";
import logo from '../../images/dec3097a-bd68-4f2a-a11c-65c0ace2580e.jfif'

function Logo({ children, ...restProps }) {
  return (
    <div>
      <a href="/" {...restProps}>
        {children}
        <img className="logo" href="/" src={logo} alt="The Tree Of Tales - Tolkien Exhibition" />
      </a>
    </div>
  );
}

export default Logo;
