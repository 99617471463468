import React from "react";
import "./MoviesStyles.css";

function CardFooter({ children, ...restProps }) {
  return (
    <small className="card-footer" {...restProps}>
      {children}
    </small>
  );
}

export default CardFooter;
