import React from "react";
import "./MoviesStyles.css";
import close from '../../images/icons/close.png'

function CardFeatureClose({ children, ...restProps }) {
  return (
    <button className="card-feature-close" type="button" {...restProps}>
      {children}
      <img src={close} alt="Close" />
    </button>
  );
}

export default CardFeatureClose;
