import React from "react";
import FooterWrapper from "../components/Footer/FooterWrapper";
// import FooterTitle from "../components/Footer/FooterTitle";
// import FooterRow from "../components/Footer/FooterRow";
// import FooterColumn from "../components/Footer/FooterColumn";
import FooterLink from "../components/Footer/FooterLink";
import rimini from "../images/MEETING-new-logo-IST-bianco.png";
import oxford from "../images/nse-6228475226791087856-a3872f64-ae75-4f07-a3d6-6faffc412cdc.jpg";
import standrews from "../images/02-standard-white.png";
import apisrl from "../images/logo_2020_w.png";
import nse from "../images/nse-231447119991649442-6fa8c250-1c68-43f8-bc41-45ce982583ee.jpg";
import newman from "../images/Logo-Newman.png";
import dtm from "../images/logo-DTM-e1627056405195.png";
import kes from "../images/KES-Achievementwhite.png";
import lollo from "../images/lollo.jpeg";

function FooterCompound() {
  return (
    <FooterWrapper>
      <a id="credits" href="https://thetreeoftales.it/" target="_blank" rel="noreferrer">Questions? Contact Us</a><br /><br />
        <FooterLink href="https://www.ox.ac.uk/" target="_blank">
            <img src={oxford} className="img-responsive" alt="Oxford, University of"/>
        </FooterLink>
        <FooterLink href="https://www.st-andrews.ac.uk/" target="_blank" >
            <img src={standrews} className="img-responsive" alt="St Andrews"/>
        </FooterLink>
        <FooterLink href="https://api-srl.com/" target="_blank">
            <img src={apisrl} className="img-responsive" alt="API SRL"/>
        </FooterLink>
        <FooterLink href="https://www.meetingrimini.org/" target="_blank">
            <img src={rimini} className="img-responsive" alt="Rimini Meeting"/>
        </FooterLink>
        <FooterLink target="_blank">
            <img src={nse} className="img-responsive" alt="nse"/>
        </FooterLink>
        <FooterLink target="_blank">
            <img src={newman} className="img-responsive" alt="newman"/>
        </FooterLink>
        <FooterLink target="_blank">
            <img src={dtm} className="img-responsive" alt="dtm"/>
        </FooterLink>
        <FooterLink target="_blank">
            <img src={kes} className="img-responsive" alt="kes"/>
        </FooterLink>
        <FooterLink target="_blank">
            <img src={lollo} className="img-responsive" alt="lollo"/>
        </FooterLink>
    </FooterWrapper>
  );
}

export default FooterCompound;

// Da fare -> 
// LOghi Homepage In Featured -> Meeting Rimini - Loghi Oxford - St Andrews - APIsrl (sotto il menu)
// Footer -> Credits (email) -> Partner & Sponsor ()

// UK -> Meno visibile Mostra Virtuale // Webinar via per Guided Tour // tour 30(min) 